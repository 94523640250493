<template>
  <div style="width: 1100px; margin: 20px auto">
    <div class="session-title" @click="toTranscript">查看电子成绩单</div>
    <div class="session-title1" @click="exportAward" style="width: 200px">
      下载证书
    </div>
    <div style="clear: both"></div>

    <div class="my-events">
      <div v-for="(item, index) in rankList" :key="index">
        <!-- <div class="numLab">{{ 'Total Socores： ' + item.total_score +'/'+ item.full_score }}</div> -->
        <!-- <div class="numLab">{{ 'Total Socores： ' + item.total_score +'/'+ item.full_score +   ' &nbsp;&nbsp;&nbsp;&nbsp;        Rank： ' +  item.percentile}}</div> -->
        <div class="events-item">
          <div>
            <!-- <div  class="showLable">{{ item.award_info.award_status == 'no award' ? '未晋级' :( item.award_info.award.award_rise == '晋级' ? ('已' +item.award_info.award.award_rise) : item.award_info.award.award_rise)}}</div> -->
            <!-- <div @click="showDetail(item)" class="showLable">查看成就</div> -->
            <div
              @click="toCertificate(item)"
              v-if="item.award_info.award_status == 'success'"
              class="showLable1"
            >
              查看证书
            </div>
          </div>

          <div style="clear: both"></div>

          <div style="padding: 0px 36px; position: relative">
            <div class="event_name">{{ item.application_event }}</div>
            <div class="scoreLable">
              {{ "Score：" + item.total_score + " / " + item.full_score }}
            </div>

            <!-- <div style="position: relative;margin-top: 10px;height: 60px;display: flex;flex-direction: row">
                        <div style="display: flex;flex-direction: row;align-items: center;">
                        <div style="color: #FFB041;font-size: 18px;font-weight: 600">开始时间</div>
                        <div style="color: #FD5900;font-size: 18px;font-weight: 600;margin-left: 10px">
                            {{ 1111}}
                        </div>
                        </div>
                        <div
                            style="background-color: red;top: 50%;transform: translateY(-50%);display: flex;flex-direction: row;align-items: center;position: absolute;right: 0">
                            <div class="event-status">状态</div>
                            <div class="event-entry" style="margin-left: 10px" @click="goExam(item)">进入考场</div>
                        </div>
                    </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getGroupAwards,
  getAwards,
  getMyAward,
  findMyTranscript,
  checkTranscriptOpen,
} from "../../api/index";
import { getUserId, getProjectCode } from "../../utils/store";
import "../../assets/common/font.css";

export default {
  data() {
    return {
      events: [
        "Artithon - Academic Marathon in Art",
        "Biothon - Academic Marathon in Biology",
        "Chemithon - Academic Marathon in Chemistry",
        "Econthon - Academic Marathon in Economics",
        "Geothon - Academic Marathon in Geography",
        "Historithon - Academic Marathon in History",
        "Mathethon - Academic Marathon in Mathematics",
        "Physithon - Academic Marathon in Physics",
        "Psychothon - Academic Marathon in Psychology",
      ],
      currentEvent: "Artithon - Academic Marathon in Art",
      groupAwards: [],
      personalAwards: [],
      myAward: {},
      show: false,
      showGroup: false,
      showCom: false,
      rankList: [],
      riseStr:''
    };
  },
  mounted() {
    // this.getGroupAwards()
    // this.getAwards()
    // this.getMyAward()
    this.findMyTranscript();
  },
  methods: {
    findMyTranscript() {
      findMyTranscript(getUserId(), getProjectCode()).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.rankList = res.data.data.score_info;
        }
      });
    },

    handleEventName(event) {
      if (event == undefined) {
        return "";
      }
      let event_array = event.split(" ");
      console.log(111, event, event_array);
      return event_array[0];
    },

    showDetail(item) {
      this.$router.push({
        path: "/awardDetail",
        query: {
          awardId: "",
          data: item,
        },
      });
    },

    exportAward() {
      checkTranscriptOpen(getProjectCode()).then((res) => {
        if (res.data) {
          this.$router.push({
            path: "/awardExport",
          });
        } else {
          this.$message.error("电子成绩单还未公布，请稍后再来查看");
        }
      });
    },

    getAwards() {
      getAwards("21-22FBLAbiz_china", "Business Communication").then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.personalAwards = res.data.data;
        }
      });
    },
    getMyAward() {
      getMyAward("6158c9dba4640b2c518b4573").then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.myAward = res.data.data;
        }
      });
    },
    getGroupAwards() {
      getGroupAwards("21-22FBLAbiz_china", "Business Communication").then(
        (res) => {
          if (res.data.code === 0) {
            this.groupAwards = res.data.data;
          }
        }
      );
    },
    chooseEvent(item) {
      this.currentEvent = item;
      this.getGroupAwards();
      this.getAwards();
    },
    upfirstName(name) {
      let transferName = name.toLowerCase();
      return transferName.charAt(0).toUpperCase() + transferName.slice(1);
    },
    rank(number) {
      if (number === 1) {
        return "st";
      } else if (number === 2) {
        return "nd";
      } else if (number === 3) {
        return "rd";
      } else {
        return "th";
      }
    },
    printPersonal() {
      let url = this.$router.resolve({
        path: "/blank",
      });
      let newWindow = window.open(url.href, "_blank"); //打印窗口要换成页面的url
      let obj = document.getElementById("cerP");

      let docStr = obj.innerHTML;

      newWindow.document.write(docStr);

      newWindow.document.close();

      newWindow.print();

      newWindow.close();
    },
    toTranscript() {
      checkTranscriptOpen(getProjectCode()).then((res) => {
        if (res.data) {
          this.$router.push({
            path: "/transcript",
          });
        } else {
          this.$message.error("电子成绩单还未公布，请稍后再来查看");
        }
      });
    },
    toCertificate(data) {
      if (
        data &&
        data.award_info &&
        data.award_info.award_status === "success"
      ) {
        this.$router.push({
          path: "/certificate",
          query: {
            score_id: data._id.$id,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-dialog {
  width: 320mm;
}
.my-events {
  width: 100%;
  margin-top: 15px;
  margin-right: 45px;
}
.numLab {
  font-size: 18px;
  color: white;
  font-family: Montserrat-SemiBold;
}

.placeLable {
  position: absolute;
  display: flex;
  right: 4px;
  width: 170px;
  height: 60px;
  color: white;
  z-index: 3;
  // background-color: red;
  top: 12px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  font-family: Montserrat-SemiBold;
}
.showLable2 {
  position: absolute;
  right: 230px;
  z-index: 3;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  background-color: #e64f29;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: row;
  text-align: center;
  line-height: 40px;
  align-content: center;
  font-family: Montserrat-SemiBold;
}
.showLable {
  position: absolute;
  right: 120px;
  z-index: 3;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  background-color: #e64f29;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: row;
  text-align: center;
  line-height: 40px;
  align-content: center;
  font-family: Montserrat-SemiBold;
}

.showLable1 {
  position: absolute;
  right: 10px;
  z-index: 3;
  width: 100px;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  background-color: #e64f29;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: row;
  text-align: center;
  line-height: 40px;
  align-content: center;
  font-family: Montserrat-SemiBold;
}
.event_name {
  font-size: 36px;
  margin-top: 0px;
  font-weight: 900;
  color: #00348b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Montserrat-SemiBold;
}
.scoreLable {
  font-size: 24px;
  margin-top: 0px;
  font-weight: 600;
  width: 300px;
  color: #e64f29;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Montserrat-SemiBold;
}
.events-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 110px;
  margin-right: 45px;
  margin-bottom: 30px;
  margin-top: 15px;
  font-family: Montserrat-SemiBold;
  background: #e4eaff;
  position: relative;
}

.my-events {
  width: 100%;
  margin-top: 35px;
  margin-right: 45px;
}

.session-title {
  float: left;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  align-content: center;
  text-align: center;
  width: 150px;
  height: 40px;
  border-radius: 4px;
  background-color: #00348b;
  line-height: 40px;
  font-family: Montserrat-SemiBold;
  cursor: pointer;
}

.session-title1 {
  float: left;
  font-weight: 600;
  color: #2d50b0;
  font-size: 18px;
  align-content: center;
  text-align: center;
  width: 130px;
  margin-left: 10px;
  height: 38px;
  border-radius: 4px;
  background-color: white;
  line-height: 38px;
  font-family: Montserrat-SemiBold;
  border: 1px solid #00348b;
  cursor: pointer;
}
</style>
